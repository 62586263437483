export const addMinutes = (date, minutes) => {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

//method to convert amount to Norway currency
export const currencyFormatter = (amount) => {
  var formattedCurrency = new Intl.NumberFormat("nb-NB", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "NOK",
    currencyDisplay: "narrowSymbol",
  }).format(amount);

  return formattedCurrency;
};

//method to return the aws object url key
export const getKeyFromS3Url = (url) => {
  // Split the URL by "/" and get the last segment
  const segments = url?.split("/");
  return segments[segments?.length - 1]; // Return the last segment, which is the key
};

//add 2 hours ahead of current time
export const getdateTime = () => {
  const currentDate = new Date();
  currentDate?.setHours(currentDate?.getHours() + 2);
  return currentDate;
};

// Function to get the end the of currrent date
export const getEndOfDay = (date) => {
  const endOfDay = new Date(date);
  endOfDay?.setHours(23, 59, 59, 999); // Set to the last moment of the day
  return endOfDay;
};

//Fucntion to get start date of current date
export const getStartOfDay = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0, 0);
  return date;
};

//Custom Header for DatePicker calender
export const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
}) => {
  return (
    <div className="custom-header">
      <button type="button" className="arrow-button" onClick={decreaseYear}>
        {"<<"}
      </button>
      <button type="button" className="arrow-button" onClick={decreaseMonth}>
        {"<"}
      </button>
      <span className="header-text">
        {date.toLocaleString("default", { month: "long", year: "numeric" })}
      </span>
      <button type="button" className="arrow-button" onClick={increaseMonth}>
        {">"}
      </button>
      <button type="button" className="arrow-button" onClick={increaseYear}>
        {">>"}
      </button>
    </div>
  );
};

//Spinner loader for buttons
export const ButtonSpinner = () => {
  return (
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  );
};

// Sorting events based on event date in ascending order
export const sortingData = (events) => {
  const sortedEvents = events?.sort((a, b) => {
    const dateA = new Date(a.get("date"));
    const dateB = new Date(b.get("date"));
    return dateA - dateB;
  });
  return sortedEvents;
};

//handle show hide eye icon for form input fields
export const handleShowHidePassword = (eleId) => {
  const ele1 = document.getElementById(eleId);
  let node1 = ele1.childNodes[0];
  let node2 = ele1.childNodes[1];

  if (node1.type === "text") {
    node1.type = "password";
    node2.innerHTML = '<i class="bi bi-eye-slash"></i>';
  } else {
    node1.type = "text";
    node2.innerHTML = '<i class="bi bi-eye"></i>';
  }
};