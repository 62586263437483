// import React, { lazy } from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { Header, Footer } from "./components/shared";
// import { ContextProvider } from "MyContext";
// import RequiredContext from "RequiredContext";
// import "./App.css";

// import Home from "./views/Home/Home";
// import EventDetail from "./views/EventDetail";
// import Checkout from "./views/Checkout";
// import About from "./views/pages/About";
// import Terms from "./views/pages/Terms";
// import Login from "./views/Auth/Login";
// import Signup from "./views/Auth/Signup";
// import PasswordReset from "./views/Auth/PasswordReset";
// import Profile from "./views/Profile/Index";
// import Tickets from "./views/Tickets";
// import Return from "./views/Return";
// import Admin from "./views/Admin";
// import NotFound from "views/pages/NotFound";
// import AddCoupon from "views/Admin/AddCoupon";
// import Stepper from "views/Admin/Stepper/Index";
// import Dashboard from "views/Dashboard/Index";
// import Tono from "views/pages/Tono";

// // const Home = lazy(() => import("./views/EventDetail"));
// // const EventDetail = lazy(() => import("./views/EventDetail"));
// // const Checkout = lazy(() => import("./views/Checkout"));
// // const About = lazy(() => import("./views/pages/About"));
// // const Terms = lazy(() => lazy(() => import("./views/pages/Terms")));
// // const Login = lazy(() => import("./views/Auth/Login"));
// // const Signup = lazy(() => import("./views/Auth/Signup"));
// // const PasswordReset = lazy(() => import("./views/Auth/PasswordReset"));
// // const Profile = lazy(() => import("./views/Profile/Index"));
// // const Tickets = lazy(() => import("./views/Tickets"));
// // const Return = lazy(() => import("./views/Return"));
// // const Admin = lazy(() => import("./views/Admin"));
// // const NotFound = lazy(() => import("views/pages/NotFound"));
// // const AddCoupon = lazy(() => import("views/Admin/AddCoupon"));
// // const Stepper = lazy(() => import("views/Admin/Stepper/Index"));
// // const Dashboard = lazy(() => import("views/Dashboard/Index"));
// // const Tono = lazy(() => import("views/pages/Tono"));

// function App() {
//   return (
//     <BrowserRouter>
//       <div className="container-fluid">
//         <ContextProvider>
//           <Header />
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/signup" element={<Signup />} />
//             <Route path="/login" element={<Login />} />
//             <Route path="/password-reset" element={<PasswordReset />} />
//             <Route path="/about" element={<About />} />
//             <Route path="/terms" element={<Terms />} />
//             <Route path="/events/addEvent" element={<Stepper />} />
//             <Route path="/eventEdit/:id" element={<Stepper />} />
//             <Route path="/event/:id" element={<EventDetail />} />
//             <Route path="/checkout" element={<Checkout />} />
//             <Route path="/return" element={<Return />} />
//             <Route
//               path="/events"
//               element={
//                 <RequiredContext>
//                   <Admin />
//                 </RequiredContext>
//               }
//             />
//             <Route
//               path="/coupon"
//               element={
//                 <RequiredContext>
//                   <AddCoupon />
//                 </RequiredContext>
//               }
//             />
//             <Route
//               path="/tickets"
//               element={
//                 <RequiredContext>
//                   <Tickets />
//                 </RequiredContext>
//               }
//             />
//             <Route
//               path="/profile"
//               element={
//                 <RequiredContext>
//                   <Profile />
//                 </RequiredContext>
//               }
//             ></Route>
//             <Route
//               path="/dashboard"
//               element={
//                 <RequiredContext>
//                   <Dashboard />
//                 </RequiredContext>
//               }
//             ></Route>
//             <Route
//               path="/tono/:id"
//               element={
//                 <RequiredContext>
//                   <Tono />
//                 </RequiredContext>
//               }
//             ></Route>
//             <Route path="*" element={<NotFound />} />
//           </Routes>
//           <Footer />
//         </ContextProvider>
//       </div>
//     </BrowserRouter>
//   );
// }

// export default App;
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Header, Footer } from "./components/shared";
import { ContextProvider } from "MyContext";
import RequiredContext from "RequiredContext";
import "./App.css";
import Spinner from "components/shared/Spinner";
import "react-datepicker/dist/react-datepicker.css";


// Lazy load views
const Home = lazy(() => import("./views/Home/Home"));
const EventDetail = lazy(() => import("./views/EventDetail"));
const Checkout = lazy(() => import("./views/Checkout"));
const About = lazy(() => import("./views/pages/About"));
const Terms = lazy(() => import("./views/pages/Terms"));
const Login = lazy(() => import("./views/Auth/Login"));
const Signup = lazy(() => import("./views/Auth/Signup"));
const PasswordReset = lazy(() => import("./views/Auth/PasswordReset"));
const Profile = lazy(() => import("./views/Profile/Index"));
const Tickets = lazy(() => import("./views/Tickets"));
const Return = lazy(() => import("./views/Return"));
const Admin = lazy(() => import("./views/Admin"));
const NotFound = lazy(() => import("views/pages/NotFound"));
const AddCoupon = lazy(() => import("views/Admin/AddCoupon"));
const Stepper = lazy(() => import("views/Admin/Stepper/Index"));
const Dashboard = lazy(() => import("views/Dashboard/Index"));
const Tono = lazy(() => import("views/pages/Tono"));
const TonoForm = lazy(()=>import("./views/Tono/index"))

function App() {
  return (
    <BrowserRouter>
      <div className="container-fluid">
        <ContextProvider>
          <Header />
          <Suspense fallback={<Spinner/>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/events/addEvent" element={<Stepper />} />
              <Route path="/eventEdit/:id" element={<Stepper />} />
              <Route path="/event/:id" element={<EventDetail />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/return" element={<Return />} />
              <Route path="/tonoForm" element={<TonoForm/>}/>
              <Route
                path="/events"
                element={
                  <RequiredContext>
                    <Admin />
                  </RequiredContext>
                }
              />
              <Route
                path="/coupon"
                element={
                  <RequiredContext>
                    <AddCoupon />
                  </RequiredContext>
                }
              />
              <Route
                path="/tickets"
                element={
                  <RequiredContext>
                    <Tickets />
                  </RequiredContext>
                }
              />
              <Route
                path="/profile"
                element={
                  <RequiredContext>
                    <Profile />
                  </RequiredContext>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <RequiredContext>
                    <Dashboard />
                  </RequiredContext>
                }
              />
              <Route
                path="/tono/:id"
                element={
                  <RequiredContext>
                    <Tono />
                  </RequiredContext>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Footer />
        </ContextProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;