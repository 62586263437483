import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { auth } from "services";
import { api } from "services";
import { useTranslation } from "react-i18next";
import logo from "../../Assets/images/logo.svg";
import Parse from "parse";

const Header = () => {
  const [userRole, setUserRole] = useState("");
  const [ticketId,setTicketId] = useState();
  const [countryLanguage, setCountryLanguage] = useState();
  const { t, i18n } = useTranslation(["common", "home"]);
  const userData = auth.fetchUser();
  const navigate = useNavigate();
  const user = auth.fetchUser();


  //get current logged in user role
  const getRole = async () => {
    const res = await api.getCurrentUserRoles();
    if (res === "user") {
      setUserRole("user");
    } else {
      setUserRole(res);
    }
  };

  useEffect(() => {
    let currentLanguage = i18n.language;
    setCountryLanguage(currentLanguage);
    getRole();
  }, []);

  //method for logout user
  const Logout = async () => {
    await Parse.User.logOut();
    localStorage.clear();
    sessionStorage.clear();
    setUserRole("");
    navigate("/");
  };

  //handle user not logged In
  const nonLoggedIn = () => {
    return (
      <Link to="/login" className="text-reset text-decoration-none fs-5">
        <div className="mt-1">
          <FontAwesomeIcon icon={faUser} className="mx-0" />
          <p className="d-inline fs-4 px-2">{t("login")}</p>
        </div>
      </Link>
    );
  };

  //handle user already logged In
  const loggedIn = () => {
    getRole();
    return (
      <div className="d-md-flex">
        <div class="dropdown">
          <a
            class="nav-link dropdown-toggle fs-5 fw-bold"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            href=" "
          >
            {user?.get("username")}
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownMenuLink"
          >
            {userRole === "user" && (
              <>
                <li className="cursor">
                  <p
                    className="dropdown-item m-0"
                    onClick={() => navigate("/tickets")}
                  >
                    {t("dropdown.myTickets")}
                  </p>
                </li>
              </>
            )}
            <li>
              {userRole !== "user" && (
                <p
                  className="dropdown-item m-0"
                  onClick={() => navigate("/events")}
                  role="button"
                >
                  {t("dropdown.event")}
                </p>
              )}
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>

            <li>
              <p
                class="dropdown-item m-0"
                onClick={() => navigate("/profile")}
                role="button"
              >
                {t("dropdown.profile")}
              </p>
            </li>
            <li><div>
            <button
              type="button"
              className="btn btn-secondary dropdown-item m-0"
              data-bs-toggle="modal"
              data-bs-target="#checkTicket"
            >
              Check Ticket
            </button>
          </div></li>
            <li>
              <a class="dropdown-item" href=" " role="button" onClick={Logout}>
                {t("dropdown.logout")}
              </a>
            </li>
          </ul>
        </div>
        <div
        className="modal fade"
       id="checkTicket"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 fw-bolder" id="exampleModalLabel">
               Event CheckIn
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form >
                <label htmlFor="" className="fw-bold mb-2">Ticket Id</label>
                <input type="text" className="form-control" placeholder="Enter Ticket Id" value={ticketId || "" } onChange={(e)=>setTicketId(e.target.value)}  />
                <br />
                <div className="d-flex">

                <button className="btn btn-secondary me-2">Verify</button>
                <button className="btn btn-secondary">CheckIn</button>
                </div>
              </form>

            </div>
            {/* <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Save changes
              </button>
            </div> */}
          </div>
        </div>
      </div>
        
      </div>
    );
  };

  //handle change browser language
  const handleLanguage = (language) => {
    i18n.changeLanguage(language);
    setCountryLanguage(language);
    localStorage.setItem("language", JSON.stringify(language));
  };

  //handle to show the language
  const showLanguage = () => {
    if (countryLanguage === "en") {
      return "EN";
    } else if (countryLanguage === "nw") {
      return "NO";
    }
  };

  console.log("ticketId ",ticketId)

  return (
    <nav className="navbar navbar-expand-lg mb-md-3">
      <div className="container">
        {userRole === "admin" || userRole === "organizer" ? (
          <Link className="navbar-brand" to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
        ) : (
          <Link className="navbar-brand" to="/" style={{ marginTop: "-5px" }}>
            <img src={logo} alt="logo" />
          </Link>
        )}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav w-100">
            <li className="nav-item me-auto">
              <Link
                className="nav-link active fs-5"
                aria-current="page"
                to="/about"
              >
                {t("header_title")}
              </Link>
            </li>
            <li className="nav-item">
              <div class="dropdown ">
                <button
                  className="btn dropdown-toggle fw-bold fs-5 py-1 mt-1 px-md-2 px-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {showLanguage()}
                </button>
                <ul class="dropdown-menu">
                  <li className="cursor">
                    <a
                      class="dropdown-item"
                      onClick={() => handleLanguage("en")}
                      href=" "
                    >
                      EN
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item cursor"
                      onClick={() => handleLanguage("nw")}
                      href=" "
                    >
                      NO
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item dropdown">
              {userData ? loggedIn() : nonLoggedIn()}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Header;